import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import FormInput from './components/Input/Input'
import FormInputWithPrefix from './components/InputWithPrefix/InputWithPrefix'
import FormSelect from './components/Select/Select'
import FormTextArea from './components/TextArea/TextArea'
import './Form.scss'

type ValidationTypes = {
  [key: string]: string
}

type ContentPropTypes = {
  children:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
}

export type FieldEventTypes = { currentTarget: { name: string; value: string } }

type FieldsObjectTypes = {
  [key: string]: string
}

type UseFormStateTypes = {
  validation?: (values: FieldsObjectTypes) => FieldsObjectTypes
}

type UseFormStateReturnTypes = {
  isFormDisplayed: boolean
  values: FieldsObjectTypes
  errors: FieldsObjectTypes
  validateFields(values: FieldsObjectTypes): FieldsObjectTypes
  onFieldValueChange(event: FieldEventTypes): void
  onFieldBlur(event: FieldEventTypes): void
  resetFields(): void
}

type FormPropTypes = {
  display?: boolean
  action?: string
  method?: 'post' | 'get'
  onSubmit?: () => void
  validation?: (data: ValidationTypes) => ValidationTypes
  children:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
}

export { FormInput, FormInputWithPrefix, FormSelect, FormTextArea }

const Content = ({ children }: ContentPropTypes): ReactElement => (
  <div className="form-content">{children}</div>
)

export const FormContent = memo(Content)

export const useFormState = ({
  validation,
}: UseFormStateTypes): UseFormStateReturnTypes => {
  const [isFormDisplayed, setIsFormDisplayed] = useState<boolean>(true)
  const [touched, setTouched] = useState<string[]>([])
  const [values, setValues] = useState<FieldsObjectTypes>({})
  const [errors, setErrors] = useState<FieldsObjectTypes>({})

  const onFieldValueChange = useCallback(
    (event: FieldEventTypes): void => {
      const { name, value } = event.currentTarget
      const newValues: FieldsObjectTypes = { ...values }
      newValues[name] = value
      const newTouched: string[] = [...touched, name]
      setTouched(newTouched)
      setValues(newValues)
    },
    [touched, values],
  )

  useEffect(() => {
    if (!isFormDisplayed) {
      setIsFormDisplayed(true)
    }
  }, [isFormDisplayed])

  const onFieldBlur = useCallback(
    (event: FieldEventTypes): void => {
      const { name, value } = event.currentTarget
      const newValues: FieldsObjectTypes = { ...values }
      newValues[name] = value

      if (validation) {
        const fieldErrors = validation(newValues)

        setErrors(
          touched.reduce((acc: FieldsObjectTypes, val: string) => {
            acc[val] = fieldErrors[val] || ''

            return acc
          }, {}),
        )
      }

      setValues(newValues)
    },
    [touched, validation, values],
  )

  const resetFields = useCallback(() => {
    setIsFormDisplayed(false)
    setValues({})
  }, [])

  const validateFields = useCallback(
    (formValues) => {
      if (validation) {
        const fieldErrors = validation(formValues)
        setErrors(fieldErrors)
        setTouched([...touched, ...Object.keys(fieldErrors)])

        return fieldErrors
      }

      return {}
    },
    [touched, validation],
  )

  return {
    isFormDisplayed,
    values,
    errors,
    onFieldValueChange,
    onFieldBlur,
    validateFields,
    resetFields,
  }
}

const Form = ({
  display = true,
  action = '',
  method = 'post',
  onSubmit,
  children,
}: FormPropTypes): ReactElement => {
  const handleSubmit = useCallback(
    (e) => {
      if (method === 'post') {
        e.preventDefault()
      }

      if (onSubmit) {
        onSubmit()
      }
    },
    [method, onSubmit],
  )

  return (
    <form
      action={action}
      method={method}
      onSubmit={handleSubmit}
      className="form-container"
    >
      {!!display && children}
    </form>
  )
}

export default memo(Form)
